<template>
    <div
        v-tab-focus="handleClick"
        class="c-sticky-notification-layout o-flex-vertical-center"
    >
        <Illustration
            v-if="illustrationSrc"
            :illustrationSrc="illustrationSrc"
            illustrationSize="48"
            class="c-sticky-notification-layout__illustration"
        />
        <div class="c-sticky-notification-layout__text-container">
            <div v-if="title" class="c-sticky-notification-layout__text">
                <h6>{{ title }}</h6>
            </div>
            <template v-if="subtitle">
                <div class="c-sticky-notification-layout__pipe"></div>
                <div class="c-sticky-notification-layout__text o-text--caption u-text--gray">
                    <BaseMarkdown v-if="subtitleHasMarkdown" :source="subtitle" />
                    <p v-else>
                        {{ subtitle }}
                    </p>
                </div>
            </template>
            <span v-if="cta" v-tab-focus="handleCtaClick" class="o-text--caption o-text--link">
                {{ cta }}
            </span>
        </div>
    </div>
</template>

<script>

import Illustration from '~coreModules/core/components/ui/Illustration.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';

export default {
    name: 'StickyNotificationLayout',
    components: {
        Illustration,
        BaseMarkdown,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        cta: {
            type: String,
            default: null,
        },
        destination: {
            type: [Object, String],
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        illustrationSrc: {
            type: String,
            default: null,
        },
        subtitleHasMarkdown: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'click',
        'cta-click',
    ],
    methods: {
        handleClick() {
            if (this.cta && this.destination) {
                this.$emit('click', this.destination);
            }
        },
        handleCtaClick() {
            this.$emit('cta-click');
        },
    },
};
</script>

<style lang="scss">

    .c-sticky-notification-layout {
        display: flex;

        &__illustration {
            margin-right: $nu-spacer-2;

            @include breakpoint(medium) {
                margin-right: $nu-spacer-3;
            }
        }

        &__text-container {
            flex-grow: 1;
        }

        &__text {
            @include breakpoint(medium) {
                display: inline-flex;
                padding-right: $nu-spacer-2;
            }
        }

        &__pipe {
            display: none;
            width: 1px;
            height: 16px;
            vertical-align: middle;
            background-color: $nu-gray--medium;

            @include breakpoint(medium) {
                display: inline-flex;
                margin-right: $nu-spacer-2;
            }
        }
    }

</style>
