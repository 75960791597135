<i18n>
[
    "compliance__privacyBannerCA--title",
    "compliance__privacyBannerCA--subtitle",
    "compliance__privacyBannerCA--cta",
]
</i18n>

<template>
    <StickyNotification
        :activeNotification="complianceNotificationData"
        :subtitleHasMarkdown="true"
        :shouldShow="shouldShowNotification"
        :ctaClickMethod="handleNotificationDismissed"
        @notification-dismissed="handleNotificationDismissed"
    />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
    COMPLIANCE_MODULE_NAME,
    UPDATE_COMPLIANCE_TRACKING_PREFERENCE,
} from '~coreModules/compliance/js/compliance-store';

import StickyNotification from '~coreModules/notifications/components/StickyNotification.vue';

export default {
    name: 'ComplianceStickyNotification',
    components: {
        StickyNotification,
    },
    data() {
        return {
            shouldShowNotification: true,
        };
    },
    computed: {
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'stickyNotification',
        ]),
        complianceNotificationData() {
            const complianceData = this.stickyNotification;

            return {
                ...complianceData,
                title: this.$t(complianceData.title),
                subtitle: this.$t(complianceData.subtitle),
                cta: this.$t(complianceData.cta),
            };
        },
    },
    methods: {
        ...mapActions(COMPLIANCE_MODULE_NAME, {
            updateComplianceTrackingPreference: UPDATE_COMPLIANCE_TRACKING_PREFERENCE,
        }),
        updateTrackingPreference() {
            return this.updateComplianceTrackingPreference({ trackingPreference: true });
        },
        handleNotificationDismissed() {
            this.updateTrackingPreference()
                .then(() => {
                    this.shouldShowNotification = false;
                });
        },
    },
};
</script>

<style lang="scss">

</style>
