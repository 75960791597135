export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "compliance__privacyBannerCA--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA Residents"])},
        "compliance__privacyBannerCA--subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information read our [Privacy Policy](/privacy-policy), [Notice of Collection](/privacy-policy), and [Notice on Rights to Opt Out](/opt-out-notice)."])},
        "compliance__privacyBannerCA--cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
      }
    }
  })
}
